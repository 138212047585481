import React, {useRef, useEffect, useState} from 'react';
import CustomScroll from 'react-custom-scroll';
import 'react-custom-scroll/dist/customScroll.css';
import BasketProduct from '../basketProduct';
import BasketProductSet from '../basketProductSet/BasketProductSet';
import PromocodeBlock from '../promocodeBlock';

function BasketRightBlock(props) {

  // ширина окна
  const [clientWidth, setClientWidth] = useState(window.innerWidth);
  // ссылка на кнопку
  const buttonRef = useRef(null);
  // позиция кнопки
  const [buttonPosition, setButtonPosition] = useState(0);

  const [couponApplied, setCouponApplied] = useState(false);

  const [isAvailableSetItem, setIsAvailableSetItem] = useState(true)

  // Определение что использован промокод и успешно применен
  useEffect(() => {
    let usedPromocode = props.couponList.length
        ? props.couponList[props.couponList.length - 1]
        : null;
    setCouponApplied(
        usedPromocode !== null && usedPromocode.hasOwnProperty('JS_STATUS') &&
        usedPromocode.JS_STATUS === 'APPLYED');
  }, [props]);

  useEffect(() => {
    let hasSetItem = props.basket.some(product => product.IS_SET && Array.isArray(product.SET_ITEMS));
    hasSetItem ? setIsAvailableSetItem(false) : setIsAvailableSetItem(true)
  }, [props.basket]);

  // текущая ширина экрана
  useEffect(() => {
    const handleResize = () => setClientWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // вызов функции при смене значения скролла родительского компонента
  useEffect(() => {
    if (clientWidth < 660) handleScroll(props.modalScrollPosition);
  }, [props.modalScrollPosition]);

  // вычисляем, добавляем класс если кнопка "оформить заказ" находится вне зоны видимости
  const handleScroll = (scrollPos) => {
    if (buttonRef.current) {
      setButtonPosition(buttonRef.current.offsetTop);
      if (scrollPos > buttonPosition + 90) {
        buttonRef.current.classList.add('fixed-row');
      } else {
        buttonRef.current.classList.remove('fixed-row');
      }
    }
  };

  /**
   * Метод определяет возможность удаления товара из корзины
   *
   * @param basketProduct
   * @returns {boolean}
   */
  function canRemove(basketProduct) {
    return !(basketProduct.hasOwnProperty('ID') &&
        props.nonRemovableGoods.includes(Number(basketProduct.ID)));
  }

  function onClick(event) {
    event.preventDefault();
    if (props.page === 'site') {
      props.triggerTransitionCheckout();
      location.href = props.orderPage;
    } else props.closeBasket();
  }

  const basketProductList = props.basket.map((product, key) => {
    let can_remove = canRemove(product);

    if (product.hasOwnProperty('IS_SET') && product.IS_SET &&
        product.hasOwnProperty('SET_ITEMS') &&
        Array.isArray(product.SET_ITEMS)) {
      return <BasketProductSet product={product} getMessage={props.getMessage}
                               canRemove={can_remove}
                               clientWidth={clientWidth}
                               updateBasketQuantity={props.updateBasketQuantity}
                               removeFromBasket={props.removeFromBasket}
                               key={key}/>;
    } else {
      return <BasketProduct product={product} getMessage={props.getMessage}
                            canRemove={can_remove}
                            clietWidth={clientWidth}
                            updateBasketQuantity={props.updateBasketQuantity}
                            removeFromBasket={props.removeFromBasket}
                            key={key}/>;
    }
  });

  return (
      <>
        <div className="title-h2">{props.getMessage('TITLE')}</div>

        <div className="order-detail">
          <div className="order-detail-holder" ref={props.basketHolderBlockRef}>
            <CustomScroll allowOuterScroll={true}>
              {/* Показываем текущий список твоаров в корзине */}
              {basketProductList}
            </CustomScroll>
          </div>
          {
            props.page === 'checkout' && props.nonRemovableGoods.length > 0 ?
                <div className="credit-buying-rules">
                  <span>{props.getMessage('WARRANTY_REQUIRED')}</span>
                </div> : <></>
          }
        </div>
        <div className="order-detail-total">
          <div className="row">
            <PromocodeBlock getMessage={props.getMessage}
                            couponList={props.couponList}
                            coupon={props.coupon}
                            couponDiscountPrice={props.couponDiscountPrice}
                            addCoupon={props.addCoupon}
                            removeCoupon={props.removeCoupon}/>
          </div>
          <div className="row">
            <div className="col">
              <span className="total-text">{props.getMessage('TOTAL')}:</span>
            </div>
            <div className="col col-price">
              <span className="total-price">{props.total.TOTAL_PRICE_FMT}</span>
            </div>
          </div>
          <div className="row" ref={buttonRef}>
            <button className={`btn ${parseInt(props.total.TOTAL_PRICE) <
            parseInt(props.minPriceForOrder) && !couponApplied
                ? 'not-active'
                : ''}`}
                    disabled={parseInt(props.total.TOTAL_PRICE) <
                        parseInt(props.minPriceForOrder) && !couponApplied}
                    onClick={onClick}>
              {props.getMessage('2ORDER')}
            </button>

            {
              clientWidth < 660 ?
                  <div className="basket-fixed-btn">
                    <button
                        className={`btn ${parseInt(props.total.TOTAL_PRICE) <
                        parseInt(props.minPriceForOrder) && !couponApplied
                            ? 'not-active'
                            : ''}`}
                        disabled={parseInt(props.total.TOTAL_PRICE) <
                            parseInt(props.minPriceForOrder) && !couponApplied}
                        onClick={onClick}>
                      {props.getMessage('2ORDER')}
                    </button>
                  </div> :
                  <></>
            }

            {
              parseInt(props.total.TOTAL_PRICE) <
              parseInt(props.minPriceForOrder) && !couponApplied ?
                  <div className="order-min-price-error">
                    {props.getMessage('ORDER_MIN_SUM')}
                  </div> :
                  <></>
            }
            {
              props.showCreditButton && props.page !== 'checkout' && isAvailableSetItem &&
              (parseInt(props.total.TOTAL_PRICE) >
                  parseInt(props.minPriceForOrder) &&
                  (parseInt(props.total.TOTAL_PRICE) >
                      parseInt(props.minPriceForCredit))) ?
                  <button className={`credit-order`} onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    props.closeBasket();
                    props.eventEmitter.emit('openCreditBasket');
                  }}>{props.getMessage('CREDIT_ORDER')}</button> :
                  <></>
            }
          </div>
        </div>
      </>
  );
}

export default BasketRightBlock;