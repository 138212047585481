'use strict'

import React, { useRef } from 'react'

function PromocodeBlock (props) {
  let usedPromocode = props.couponList.length ? props.couponList[props.couponList.length - 1] : false
  let firstCoupon = usedPromocode === false
  const holderRef = useRef()
  const inputRef = useRef()

  const promocodeList = props.couponList.map((coupon, key) => {
    if (coupon.JS_STATUS === 'APPLYED')
      return <div className="promocode-holder-applied simplified" key={key}>
        <div className="promocode-text-applied">
                  <span className="sub-text"
                        dangerouslySetInnerHTML={{
                          __html: `${props.getMessage('APPLYED_PROMOCODE').replace('#CODE#', `<b> ${usedPromocode.COUPON} </b>`)}
                          ${props.getMessage('PROMOCODE_PRICE')
                            .replace('#PRICE#', `<b> ${props.couponDiscountPrice[usedPromocode.COUPON]}</b>`)}`
                        }}/>
        </div>
        <span className="ic-close-sm"
              onClick={e => props.removeCoupon(coupon.COUPON)}/>
      </div>
  })

  return (
    <div className="promocode-holder" ref={holderRef}>
      {/* Показываем результат последнего примененного купона если он не сработал */}
      {
        usedPromocode !== false && usedPromocode.JS_STATUS !== 'APPLYED' ?
          <div className={`promocode-text-holder ${usedPromocode ? 'applied' : ''}`}>
            <p dangerouslySetInnerHTML={{
              __html: props.getMessage('NO_APPLYED_PROMOCODE')
                .replace('#CODE#', `<b> ${usedPromocode.COUPON} </b>`)
                .replace('#WORK_CODE#', `<b> SMILENOW </b>`)
            }}>
            </p>
            <span className="ic-close-sm js-close-code" onClick={e => props.removeCoupon({'coupon': usedPromocode.COUPON}, 'removeCoupon')}/>
          </div> :
          <></>

      }
      {/* Показываем список примененных купонов */}
      { promocodeList }
      <a href="#" className="promocode-link js-promocode-link" onClick={e => holderRef.current.classList.add('opened')}>
        {firstCoupon ? props.getMessage('FIRST_PROMO_CODE_BUTTON') : props.getMessage('PROMO_CODE_BUTTON')}
      </a>
      <div className="promocode-holder-form">
        <form className=" form-promocode" id="form-promocode">
          <div className="promocode-holder-apply">
            <div className="input-holder field">
              <input type="text" className="input form-control" id="bx-small-cart-coupon-input"
                     ref={inputRef}
                     onBlur={e => {
                       e.preventDefault()
                       e.stopPropagation()
                       if (e.target.value) e.target.classList.add('has-value')
                       else e.target.classList.remove('has-value')
                     }}/>
              <label className="label-input">{props.getMessage('PROMO_CODE')}</label>
              <button className="promocode-apply" id="bx-small-cart-coupon-button"
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        if (inputRef.current.value === '') return
                        // Очищаем и закрываем поле
                        holderRef.current.classList.remove('opened')
                        props.addCoupon(inputRef.current.value)
                      }}>
                {props.getMessage('PROMO_CODE_APPLY')}
              </button>
            </div>
          </div>
        </form>
        {/** Кнопка закрытия блока с полем ввода промокода */}
        <span className="ic-close-sm js-close-code" onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          holderRef.current.classList.remove('opened')
        }}/>
      </div>
    </div>
  )
}

export default PromocodeBlock