import React, {useEffect} from 'react';
import {eventEmitter} from '../../common/baseData';

function LoaderOverlay(props) {

  const [show, setShow] = React.useState(false);

  const onShowLoader = () => {
    setShow(true)
  }

  const onHideLoader = () => {
    setShow(false)
  }

  useEffect(() => {
    eventEmitter.on('showLoader', onShowLoader);
    eventEmitter.on('hideLoader', onHideLoader);

    return () => {
      eventEmitter.off('showLoader', onShowLoader);
      eventEmitter.off('hideLoader', onHideLoader);
    }
  }, []);

  return (
    <div className={`loader-container`}
         style={{display: show ? 'block' : 'none'}}/>
  );
}

export default LoaderOverlay;