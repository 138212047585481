'use strict'

import React from 'react'
import InfoIcon from '../images/info.svg'

function AdditionalProduct (props) {
  //console.info(props)

  const product = props.product

  function showPreview (event) {
    event.preventDefault()
    event.stopPropagation()
    // формируем и показываем окно с описанием
    import(/* webpackPreload: true */ '../../../popup')
      .then(module => {
        let popup = new module.Popup('additional-basket-products', false, false, true)
        // Устанавливаем тело окна
        popup.body =
          `<div class="additional-product-popup-title">${product.NAME}</div>` +
          `<div class="additional-product-popup-body">${product.PREVIEW_TEXT}</div>`
        popup.open()
      })
  }

  return (
    <>
      <div className="product-item">
        <div className="img">
          {
            !product.WARRANTY_DATA.WARRANTY_TYPE ?
              <a href={product.URL}>
                <img src={product.IMAGE_150_SRC} alt={product.NAME} loading={'lazy'}/>
              </a> :
              <div>
                <img src={product.IMAGE_150_SRC} alt={product.NAME} loading={'lazy'}/>
              </div>
          }
        </div>
        <div className="product-description">
          <div className="product-name">
            {
              !product.WARRANTY_DATA.WARRANTY_TYPE ?
                <a href={product.URL}>{product.NAME}</a> :
                <div className={'product-name-holder'}>
                  <span>{product.NAME}</span>
                  <img src={InfoIcon}
                       width={`14px`}
                       height={`14px`}
                       alt={`info`}
                       title={`info`}
                       onClick={showPreview}
                  />
                </div>
            }
          </div>
          <div className="btn-buy_row">
            {
              product.hasOwnProperty('DISPLAY_PRICE') ?
                <div className={`price-block ${product.DISPLAY_PRICE.hasOwnProperty('OLD_PRICE') && Number(product.DISPLAY_PRICE.OLD_PRICE) > 0 ? 'discount' : ''}`}>
                  <div className="price-box">
                    {
                      product.DISPLAY_PRICE.hasOwnProperty('OLD_PRICE') && Number(product.DISPLAY_PRICE.OLD_PRICE) > 0 ?
                        <div className="price-old">
                          <span>{product.DISPLAY_PRICE.PRINT_OLD_PRICE}</span>
                        </div> :
                        <></>
                    }
                    {
                      product.DISPLAY_PRICE.hasOwnProperty('PRICE') && Number(product.DISPLAY_PRICE.PRICE) > 0 ?
                        <div className="price">
                          <span>{product.DISPLAY_PRICE.PRINT_PRICE}</span>
                        </div> :
                        <></>
                    }
                  </div>
                </div> :
                <></>
            }
            {
              (product.WARRANTY_DATA.TYPE !== 2 ||
                (product.WARRANTY_DATA.TYPE === 2 && product.WARRANTY_DATA.RELATED_PRODUCTS_IN_BASKET)) ?
                <a className="buy-btn_mini"
                   style={{background: props.buyButtonColor}}
                   href="#"
                   onClick={e => {
                     e.preventDefault()
                     e.stopPropagation()
                     props.addToBasket(product.ID)
                   }}>
                  <i className="icon-basket_blue" data-show="in-basket"/>
                </a> :
                <></>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default AdditionalProduct