import React, {useEffect, useState} from 'react';
import BasketProductPriceBlock from '../basketProductPriceBlock';
import {decode} from 'html-entities';
import InfoIcon from '../images/info.svg';
import './BasketProductSet.css';

function BasketProductSet(props) {

  const [canRemove, setCanRemove] = useState(true);
  const product = props.product;
  const setItems = product.SET_ITEMS && Array.isArray(product.SET_ITEMS)
      ? product.SET_ITEMS
      : [];
  let percentDiscount = Number(product.DISPLAY_PRICE.PERCENT_DISCOUNT) > 0
      ? product.DISPLAY_PRICE.PERCENT_DISCOUNT
      : null;

  // установка признака вохможности обновления
  useEffect(() => {
    setCanRemove(props.canRemove);
  }, [props.canRemove]);

  return (
      <div className={'basket-set'}>
        <span className={'title'}>Разом дешевше</span>
        <div className={'basket-set-products'}>
          {
            setItems.map(setProduct => (
                <div className={'product'} key={`set-product-${setProduct.ID}`}>
                  <img className={'image'}
                       src={setProduct.IMAGE_150_SRC}
                       loading={'lazy'}
                       title={setProduct.NAME}
                       alt={setProduct.NAME}
                       width={'100px'}
                       height={'100px'}/>
                  <div className={'details'}>
                    <a className={'title'} href={setProduct.URL}>
                      {setProduct.NAME}
                    </a>
                    <span className={'code'}>{setProduct.ARTICLE}</span>
                    {
                      props.clientWidth < 666 ?
                        <div className={'prices'}>
                          {
                            setProduct.hasOwnProperty('OLD_PRICE') &&
                            setProduct.OLD_PRICE ?
                              <div className={'old-price'}>
                                <span>{setProduct.OLD_PRICE_FORMATED}</span>
                                {
                                  percentDiscount !== null
                                    ?
                                    <strong
                                      className="percent-discount">-{percentDiscount}%</strong>
                                    :
                                    <></>
                                }
                              </div> :
                              <></>
                          }
                          <span
                            className={'price'}>{setProduct.PRICE_FORMATED}</span>
                        </div> :
                        <></>
                    }
                  </div>
                  {
                    props.clientWidth >= 666 ?
                      <div className={'prices'}>
                          {
                            setProduct.hasOwnProperty('OLD_PRICE') &&
                            setProduct.OLD_PRICE ?
                                <div className={'old-price'}>
                                  <span>{setProduct.OLD_PRICE_FORMATED}</span>
                                  {
                                    percentDiscount !== null
                                        ?
                                        <strong
                                            className="percent-discount">-{percentDiscount}%</strong>
                                        :
                                        <></>
                                  }
                                </div> :
                                <></>
                          }
                          <span
                              className={'price'}>{setProduct.PRICE_FORMATED}</span>
                        </div> :
                        <></>
                  }
                </div>
            ))
          }
        </div>
        <hr className={'delimiter'}/>
        <div className={'total'}>
          <div className="quantity_controls">
            <span className={'action'}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.updateBasketQuantity(product.ID,
                        product.QUANTITY - 1);
                  }}>
              <i className="minus"/>
              </span>
            <span className="item_quantity">{product.QUANTITY}</span>
            <span className={'action'}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.updateBasketQuantity(product.ID,
                        product.QUANTITY + 1);
                  }}>
              <i className="plus"/>
            </span>
          </div>
          <div className={'set-total-price'}>
            {
                product.DISPLAY_PRICE &&
                product.DISPLAY_PRICE.hasOwnProperty('DISCOUNT_DIFF') &&
                Number(product.DISPLAY_PRICE.DISCOUNT_DIFF) > 0 &&
                (
                    <>
                      <span className={'discount'}>
                        Знижка {product.DISPLAY_PRICE.PRINT_DISCOUNT_DIFF}
                      </span>
                    </>
                )
            }
            {
                product.DISPLAY_PRICE &&
                product.DISPLAY_PRICE.hasOwnProperty('PRICE') &&
                Number(product.DISPLAY_PRICE.PRICE) > 0 &&
                (
                    <span className={'current'}>
                        {product.DISPLAY_PRICE.PRINT_PRICE}
                      </span>
                )
            }
          </div>
        </div>
        {
          canRemove ?
              <a className="close-link" href="#" onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                props.removeFromBasket(
                    {cartId: product.ID, productId: product.PRODUCT_ID});
              }}>
                <i className="ic-close-sm"/></a> :
              <></>
        }
      </div>
  );
}

export default BasketProductSet;