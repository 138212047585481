import React, { useState } from 'react'
import AdditionalProduct from '../additionalProduct'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import 'swiper/swiper-bundle.css'
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

SwiperCore.use([Navigation])

function AdditionalProductGroup (props) {
  //console.info(props)
  const swiperClass = `swiper-${props.uuid}`;

  const productList = props.group.items.map((product, key) => {
    // Просчитываем количество показываемых элементов
    return (
      <SwiperSlide key={`product-${product.ID}`}>
        <AdditionalProduct
          buyButtonColor={props.buyButtonColor}
          product={product}
          key={key}
          index={key}
          getMessage={props.getMessage}
          addToBasket={props.addToBasket}
        />
      </SwiperSlide>)
  })

  return (
    <AccordionItem className={'accordeon_item'} activeClassName={`accordeon_item active swiper-${props.group.items.length}`} uuid={props.uuid}>
      <AccordionItemHeading className={'accordeon_head_basket'}>
        <AccordionItemButton>
          <span>{props.group.title}</span>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className={'accordeon_content'}>
        <div className="product-list">
          {
            props.group.items.length ?
              <Swiper
                navigation={{
                  nextEl: `.swiper-button-next-additional.${swiperClass}`,
                  prevEl: `.swiper-button-prev-additional.${swiperClass}`,
                }}
                spaceBetween={20}
                slidesPerView={3}
                freeMode={false}
                breakpoints={{
                  320: { slidesPerView: 2.2, spaceBetween: 10, speed: 600, freeMode: true},
                  375: { slidesPerView: 2.2, spaceBetween: 10, speed: 600, freeMode: true},
                  661: { slidesPerView: 1, spaceBetween: 20 },
                  768: { slidesPerView: 2, spaceBetween: 20 },
                  1024: { slidesPerView: 2, spaceBetween: 20 },
                  1151: { slidesPerView: 3, spaceBetween: 20 },
                }}>
                {productList}
              </Swiper> :
              <></>
          }
        </div>
      </AccordionItemPanel>
      <div className={`swiper-button-prev-additional ${swiperClass}`}></div>
      <div className={`swiper-button-next-additional ${swiperClass}`}></div>
    </AccordionItem>
  )
}

export default AdditionalProductGroup