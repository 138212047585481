import React from 'react'

/**
 * old price
 *
 * 1. Если есть скидка и заполнена старая цена (тип цен)
 * Показываем цену из типа цен старая
 *
 * 2. Скидки нет, но заполнена старая цена (тип цен)
 * Показываем цену из типа цен старая
 *
 * 4. Если есть скидка, но не заполнена старая цена (тип цен)
 * Показываем базовую цену товара
 *
 * 3. Скидки нет и не заполнена старая цена (тип цен)
 * Не показываем старую цену
 *
 *
 * price
 *
 * Текущая цена с учетом скидки
 *
 */
function BasketProductPriceBlock (props) {
  const prices = props.product.DISPLAY_PRICE;

  // Признак скидки
  const price = (props.product.GIFT) ? props.getMessage('GIFT') : prices.PRINT_PRICE;
  const oldPrice = Number(prices.OLD_PRICE) > 0;
  const oldPriceFmt = oldPrice ? prices.PRINT_OLD_PRICE : '';
  const usdPrice = Number(prices.USD_PRICE) > 0;
  const usdPriceFmt = usdPrice ? prices.PRINT_PRICE_USD : '';
  const percentDiscount = Number(prices.PERCENT_DISCOUNT) > 0 ? prices.PERCENT_DISCOUNT : null;

  return (
    <div className={`price-block ${Number(prices.OLD_PRICE) > 0 ? 'discount' : ''}`}>
      <div className="price-box">
        {
          oldPrice && (
            <div className="price-old">
              <span>{oldPriceFmt}</span>
              {
                percentDiscount !== null && (
                  <strong className="percent-discount">-{percentDiscount}%</strong>
                )
              }
            </div>
          )
        }
        <div className="price"><span>{price}</span></div>
      </div>
      {
        usdPrice && (
          <div className="price-usd-basket">{(props.product.GIFT)
            ? '0 $'
            : usdPrice}
          </div>
        )
      }
    </div>
  )
}

export default BasketProductPriceBlock